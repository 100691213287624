// Our colors
// $si-slate-blue: #445c6e !default; /* primary */
$si-slate-blue: #0F0F49 !default; /* primary */
$si-orange: #BF7800 !default; /* secondary it was ff9a35*/
$si-white: #ffffff;

$si-slate-blue-lighter: #62829a !default;

// study category colors
$si-category-red: rgb(230, 60, 80);
$si-category-yellow: rgb(255, 240, 60);
$si-category-green: rgb(90, 160, 70);
$si-category-none: rgb(255, 255, 255);

// button styles
$action-button-background: $si-orange;
$cancel-button-background: $si-orange;
$confirm-button-background: $si-slate-blue;
$action-button-hover-background: lighten($si-orange, 10%);
$cancel-button-hover-background: lighten($si-orange, 10%);
$confirm-button-hover-background: lighten($si-slate-blue, 10%);

// Shadow styles
$z-depth-1:          0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$z-depth-1-half:     0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
$z-depth-2:          0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$z-depth-3:          0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
$z-depth-4:          0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
$z-depth-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);

// Guidelines colours
$orange-6: #BF7800; /* primary */
$orange-6-d: rgba(191, 120, 0, 0.9); /* primary disabled*/
$orange-5: #FFA000; /* accent */
$blue-6: #0F0F49; /* secondary */
$blue-5: #141464; /* accent */
$grey-6: #333333; /* tertiary */
$grey-5: #595959; /* accent */
