// define 3 theme color
@use '@angular/material' as mat;
@import '@angular/material/theming';

//   $headline: mat.define-typography-level(32px, 48px, 700),

$custom-typography: mat.define-typography-config(
  $font-family: '"open sans", "Lucida Grande", "Helvetica Neue", Helvetica, Arial, Verdana , sans-serif',
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$si-slate-blue-vals: (
  50: #dfe6ec,
  100: #d0dae2,
  200: #c0ced8,
  300: #b0c1cf,
  400: #90a9bb,
  500: #819cb1,
  600: #7170a8,
  700: #57768e,
  800: #4e687e,
  900: #445c6e,
  A100: #3a4f5f,
  A200: #30424f,
  A400: #27353f,
  A700: #1d272f,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

$si-orange-vals: (
  50: #fff2e6,
  100: #fff2e6,
  200: #ffe6cc,
  300: #ffd9b3,
  400: #ffbf80,
  500: #ffb366,
  600: #ffa64d,
  700: #ff9a35,
  800: #ff8c1a,
  900: #ff8000,
  A100: #e67300,
  A200: #cc6600,
  A400: #b35900,
  A700: #994d00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

// mat-palette accepts $palette-name, main, lighter and darker variants
$si-app-primary: mat.define-palette($si-slate-blue-vals, 900, 300, A400);
$si-app-accent: mat.define-palette($si-orange-vals, 700, 300, 900);
$si-app-warn: mat.define-palette(mat.$red-palette);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$si-app-theme: mat.define-light-theme(
    $si-app-primary,
    $si-app-accent,
    $si-app-warn
);

@include mat.all-legacy-component-themes($si-app-theme);
::ng-deep .graph .mat-mdc-slider .mdc-slider__thumb-knob
{
  height: 22px;
  width: 5px;
  border-radius: 0px;
  border: 1px solid #1e1e1e;
  background-color: #33ff33;
  box-shadow: 1px 0 #fff;
}
::ng-deep .graph .mat-mdc-slider .mdc-slider__thumb-knob:nth-child(1)
{
  background-color: #33ff33!important;
}
::ng-deep .graph .mat-mdc-slider .mdc-slider__thumb-knob:nth-child(2)
{
  background-color: #ff3333;
}
::ng-deep .graph .mat-mdc-slider .mdc-slider__track--active .mdc-slider__track--active_fill
{
  border-color: #ff9a35!important;
}
.btn-primary:disabled, .btn-primary.disabled
{
    color: #fff;
    background-color: $orange-6-d;
    border-color: $orange-6;
}