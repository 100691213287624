
.login-main-container, .signup-main-container, .signin-main-container {
  margin-top:45px;
  margin-bottom:5px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}