/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

/* Import our own variables */
@import 'shared/variables';


/***
 * BOOTSTRAP SETUP
 ***/
/* import our own bootstrap variables to be used by reboot and grid */
@import 'shared/override-bootstrap-variables';

/*
 * The loading of the bootstrap scss must be after our own overrides.
 * We load two of bootstraps styles:
 *   bootrap's reboot to make all browsers look similar
 *   bootrap's grid to layout our views
 */
@import './node_modules/bootstrap-scss/bootstrap-reboot';
@import './node_modules/bootstrap-scss/bootstrap-grid';

/***
 * MATERIAL SETUP
 ***/

/* import our own material theme */
@import 'si-theme.scss';
.si-app-theme
{
  @include mat.all-component-themes($si-app-theme);
}

@font-face
{
    font-family: Sansation;
    src:  url('./assets/vendor/CDN/sansation/fonts/Sansation_Regular.ttf'),
          url('./assets/vendor/CDN/sansation/fonts/Sansation_Bold.ttf'),
          url('./assets/vendor/CDN/sansation/fonts/Sansation_Italic.ttf'),
          url('./assets/vendor/CDN/sansation/fonts/Sansation_Bold_Italic.ttf'),
          url('./assets/vendor/CDN/sansation/fonts/Sansation_Light.ttf'),
          url('./assets/vendor/CDN/sansation/fonts/Sansation_Light_Italic.ttf');
}

// * :not(.si-table-menu > *){
//     font-family: Sansation;
// }
.container-fluid
{
    font-family: Sansation;
}

/* override some material color schemes */
.mat-card
{
    color: rgba($si-slate-blue, 0.87);
}
.mat-form-field-required-marker
{
    color: $si-orange;
}

/***
 * AG-GRID SETUP
 ***/
/* import ag-grid styles and set it's Sass variables to our own style */
$ag-icons-path: "~ag-grid/src/styles/icons/";
$active: #ffff00;
$primary-color: $si-slate-blue;
$accent-color: $si-orange;
//$odd-row-background-color: $si-orange;
$font-size: 14px;
// $font-family: "open sans", "Lucida Grande", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
$font-family: "Sansation", "Lucida Grande", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
$header-background-image: linear-gradient(rgb(255, 255, 255), rgb(204, 204, 204));
$selected-color: $si-orange;
//$hover-color: lighten($si-orange, 10%);
$hover-color: $orange-6;

//$ag-range-selected-color-1: lighten($si-orange, 10%);
$border-color: #BDC3C7;

/*@import "~ag-grid/src/styles/ag-grid.scss"; */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
//@import "~ag-grid/src/styles/ag-theme-balham.scss";
//@import "~ag-grid/dist/styles/ag-theme-balham.css";
//@import "~ag-grid/dist/styles/ag-theme-bootstrap.css";
//@import "~ag-grid/dist/styles/ag-theme-material.scss";

/* tables */
.si-table-data
{
    height: auto
}
.si-table
{
    height: calc(65vh);
    min-height: 350px;
}

.si-study-list-table
{
    height: calc(65vh);
    min-height: 100px;
}

.si-table-menu
{
    margin-top:10px;
}
.si-table-menu .menu-button
{
    padding-left: 0;
    padding-right: 0;
}
.si-table-menu .glyphicon
{
    margin-right: 12px;
    font-size: 22px;
    cursor: pointer;
}
.si-table-menu .glyphicon:hover
{
    color: $si-orange;
}
.si-table-control .glyphicon
{
    margin-right: 12px;
}
.si-table-error
{
    color: red;
    font-size: 12px;
}

/* table field-action column */
.si-table .ag-row .field-action
{
    padding: 4px;
}


/***
 * GENERAL STYLES
 ***/
body
{
    //font-family: "open sans", "Lucida Grande","Helvetica Neue","Helvetica","Arial","Verdana","sans-serif";
    font-family: "Sansation", "Lucida Grande","Helvetica Neue","Helvetica","Arial","Verdana","sans-serif";
    color: $si-slate-blue;
    margin: 0px;
    padding:0px;
    min-height:100vh;
    display:flex;
    flex-direction:column;
}

.view-container 
{
    position: relative;
}

body,
.view-container,
.view-content,
.page 
{
    height: 100%;
    min-height: 100%;
}

.page::after 
{
    clear: both;
}

/*footer arrow at bottom */
.page::after 
{
    content: ' ';
    display: block;
    height: 45px;
}

/* top header menu items */
a.header-menu-item
{
    font-size: 14px;
    padding: 0 15px;
    text-decoration: none;
    display: inline-block;
    height: 78px;
    border-radius: 6px;;

    &.active
    {
        color: $si-orange;
    }
    &:hover
    {
        color: $si-white;
        background-color: #aa7d50;
    }
    .item-text
    {
        margin-top: 20px;
        display: block;
    }
}

a.green-pointer
{
    font-size: 14px;
    padding: 0 15px;
    text-decoration: none;
    display: inline-block;
    height: 78px;
    border-radius: 6px;;

    &:hover
    {
        color: $si-white;
        background-color: #aa7d50;
    }
    .item-text
    {
        margin-top: 20px;
        display: block;
    }
}

.logo a
{
    text-decoration: none;
}

/* Hover effects on buttons */
button.si-action, button.si-confirm, button.si-cancel
{
    &:hover 
    {
        box-shadow: $z-depth-1-half;
    }
}
button.si-action
{
    background-color: $action-button-background;
    &:hover 
    {
        background-color: $action-button-hover-background;
    }
}
button.si-confirm
{
    background-color: $confirm-button-background;
    &:hover 
    {
        background-color: $confirm-button-hover-background;
    }
}
button.si-cancel
{
    background-color: $cancel-button-background;
    &:hover 
    {
        background-color: $cancel-button-hover-background;
    }
}


/* The title in each of the main windows (ie: Patient List) */
.si-main-view-title
{
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 4px;
    text-align: left;
}

/* full-width button */
.button-full-width
{
    width: 100%;
}

/* forms */
.form-horizontal mat-form-field
{
    width: 100%;
}

/* status messages */
.si-message
{
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 5;
    width:100% !important;
    /*margin-bottom: 20px;*/
    padding: 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    }
.alert
{
    text-align: center;
    padding: 15px;

    /* default colors */
    background-color: rgba(200, 200, 200, 0.87);
    color: darken(rgb(200, 200, 200), 50%);
    border-color: lighten(rgb(200, 200, 200), 10%);
}
.alert.alert-success
{
    background-color: rgba(223, 240, 216, 0.87);
    color: darken(rgb(223, 240, 216), 50%);
    border-color: lighten(rgb(223, 240, 216), 10%);
}
.alert.alert-error
{
    background-color: rgba(242, 222, 222, 0.87);
    color: darken(#f2dede, 50%);
    border-color: lighten(#f2dede, 10%);
}

.alert.alert-danger
{
    color:#a94442;
    background-color:#f2dede;
    border-color:#ebccd1
}

/* Study Category colors */
.category-bk-red
{
    background-color: $si-category-red;
}
.category-bk-yellow
{
    background-color: $si-category-yellow;
}
.category-bk-green
{
    background-color: $si-category-green;
}
.category-bk-none
{
    background-color: $si-category-none;
}

.ag-header-cell
{
    background: rgb(140,138,138);
    background: linear-gradient(0deg, 
    rgba(140,138,138,1) 0%,
    rgba(219,219,219,0.8715861344537815) 6%, 
    rgba(255,255,255,1) 100%, 
    rgba(255,255,255,1) 100%);
}
.ag-header-cell-text
{
    font-size: 16px;
    font-weight: 600;
}

/* SECTION - TABLES */
.report .report-tables
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.report-tables > .table-container
{
    display: flex;
    flex: 0 48%;
    align-items: end;
    justify-content: center;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}

.report-tables > .table-container table
{
    margin-left: auto;
    margin-right: auto;
    /* spacing around the tables */
    border-spacing: 4px 3px;
    border-collapse: separate;
}
.report-tables .table-container table > tbody
{
    text-align: center;
}
.report-tables .table-container table > tbody > tr > td,
.report-tables .table-container table > thead > tr > td
{
    padding: 3px 5px;
}
.report .report-tables td
{
    background: rgb(146, 146, 146);
    color: white;
    min-width: 40px;
    border-radius: 5px;
}
.report-tables .table-container table thead tr th
{
    background: transparent;
    color: gray;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
}

.report-tables table.two-columns td:first-child,
.report-tables table.three-columns td:first-child,
.report-tables table.four-columns td:first-child
{
    min-width: 210px;
    max-width: 210px; /* needed for expansion of info area */
}
.report-tables table.two-columns td:last-child
{
    min-width: 140px;
}
.report-tables table.three-columns td:not(first-child)
{
    min-width: 70px;
}
.report-tables table.four-columns td:not(first-child)
{
    min-width: 45px;
}

.report-tables table.two-line-header thead tr
{
    line-height: 1.1;
}
.report-tables table.two-line-header thead tr th
{
    padding-top: 0px;
    padding-bottom: 0px;
}

.report .row.push-down,
.report .push-down
{
    margin-top: 20px;
}
.report .disclaimer
{
    margin-top:15px;
    font-size: smaller;
}

/* patientData.css */
/*
 * DASHBOARD
 */
.dashboard-section
{
    border-bottom: 1px solid #ffceae;
    box-shadow: 0 2px 5px rgba(255,154,53,0.6);
    border-radius: 10px;
}

.dashboard-section.split-top
{
    border-radius: 10px 10px 0px 0px;
    height: 43px;
}

.dashboard-section.split-bottom
{
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 20px;
    padding-top: 15px;
}

.dashboard-section.full-width
{
    margin-left: 0px;
    margin-right: 0px;
}

.dashboard-title
{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 4px;
    margin-bottom: 20px;
}
.dashboard-title.ng-leave-active
{
    position: absolute;
    opacity: 0;
    display: none;
    }

.content-section
{
    background-color: rgba(230,230,230,0.4);
    box-shadow: 0 2px 5px rgba(255,154,53,0.6);
    min-height: 700px;
}

.title-flip
{
    position: absolute;
    top: 0;
    left:15px;
}
.header-flip
{
    float:right;
    z-index: 1000;
    padding: 3px 5px;
    background-color: $orange-6;//#ff9a35;
    color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(100, 100, 100, 0.4), 0 0 40px rgba(100, 100, 100, 0.1) inset;
    transition: all 250ms;
}
.header-flip
{
    color: #ffffff;
    cursor: pointer;
}
.header-flip:hover
{
    box-shadow: rgba(0, 0, 0, 0.3) 0 10px 24px 0, 0 0 40px rgba(100, 100, 100, 0.1) inset;
    background-color: $orange-5;
}
.title-flip.ng-leave-active,
.header-flip.ng-leave-active
{
    opacity: 0;
    display: none;
}

/* for decision Assist listing */
.scale-height
{
    max-height:550px;
    min-height:260px;
    overflow: auto;
}

.detail-study-component.col-xs-12-add
{
    transition: width ease-in-out 0.5s 0.2s,
                margin-left ease-in-out 0.5s 0.2s;
}

/* animations */
.scale-height.ng-enter
{
    -webkit-animation: scale-up 0.5s 0.5s;
    animation: scale-up 0.8s 0.5s;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.scale-height.ng-leave
{
    -webkit-animation: scale-down 0.5s 0.1s;
    animation: scale-down 0.5s 0.1s;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.content-blur.ng-enter
{
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.0s 0.8s;
}
.content-blur.ng-leave
{
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s 0.1s;
}

.content-blur.ng-enter
{
    opacity:0;
}
.content-blur.ng-enter-active
{
    opacity:1;
}

.content-blur.ng-leave
{
    opacity:1;
}
.content-blur.ng-leave-active
{
    opacity:0;
}


/*
 * STUDY CATEGORIZATION
 */
.categorization-tab
{
    display:table-cell;
    width :35px;
    height:50px;
    background-color:#ffffff;
    min-height:50px;
    box-shadow: 1px 0 2px 0 rgba(153, 153, 153, 1);
}
.categorization-container-bare .categorization-tab
{
    border-radius: 4px 0 0 4px;
}
.categorization-tab.category-green
{
    background-color: #009900; /* rgb(0, 153, 0) */
}
.categorization-tab.category-yellow
{
    background-color: #ffcc00; /* rgb(244, 204, 0) */
}
.categorization-tab.category-red
{
    background-color: #cc0000; /* rgb(204, 0, 0) */
}
.categorization-tab.category-none
{
    background-color: #ffffff;
}
.categorization-tab-label
{
    display:table-cell;
    padding-left: 10px;
    vertical-align: middle;
}
.categorization-numbers
{
    margin-bottom: 10px;
}
.categorization-numbers .item
{
    font-size: 16px;
    font-weight: 500;
}

/* For the Overview sleep data category colors */
.categorization-numbers .category-green
{
    color: #009900; /* rgb(0, 153, 0) */
}
.categorization-numbers .category-yellow
{
    color: #ffcc00; /* rgb(244, 204, 0) */
}
.categorization-numbers .category-red
{
    color: #cc0000; /* rgb(204, 0, 0) */
}
/* we need the text slightly darker to work with the background */
.categorization-numbers .category-none
{
    color: rgb(200, 200, 200);
}

/*
 * DECISION ASSIST
 */
/* override for decision assist to get categorization color tab */
.assist uib-accordion .panel-heading
{
    padding: 0px 0px;
}
/* This is needed for Info popup to not be cut-off */
.assist uib-accordion .item.panel:last-of-type
{
    margin-bottom:50px;
}
.categorization-numbers
{
    margin-bottom: 30px;
    padding-top: 10px;
    transition: background 0.35s ease-in-out;
}
.categorization-numbers.gray-background
{
    background-color: rgba(220, 220, 220, 0.2);
    border-radius: 8px;
}
.categorization-numbers .item
{
    padding: 0 5px;
    text-align: center;
}
.categorization-numbers .larger
{
    transition: transform 0.35s ease-in-out;
    transform: translate(10px,-3px) scale(1.5);
    text-shadow: 0px 10px 5px rgba(0, 0, 0, 0.4);
}

/* info tag expanded content */
.categorization-numbers .info-expanded .action-nav
{
    font-size: 18px;
}
.categorization-numbers .info-expanded .action-nav .glyphicon
{
    text-shadow: -1px 1px 2px #ccc;
    cursor: pointer;
}
.categorization-numbers .info-expanded .action-nav .glyphicon:hover
{
    color: #ff9a35;
}
.categorization-numbers .info-expanded .selected-info
{
    font-weight: bold;
}

/* STUDY DETAILS - page menu */
.page-menu-item
{
    width: 49%;
    padding: 5px 10px;
    font-size: 1.3em;
    cursor: pointer;
}

.page-selected
{
    // border: 1px solid rgba(255,154,53,0.6);
    // color: #ff9a35;
    border: 1px solid $orange-6;
    color: $orange-6;
    background-color: #ffffff;
}

.link-disabled
{
    cursor: not-allowed;
    pointer-events: none;
    color: grey;
    opacity: 0.75;
}

/* study listing */
.studyList label, .study-list label
{
    font-weight: 400;
}

/* report tab top menu */
.report-menu
{
    /* display: inline-block; */
    position: absolute;
    top: 55px;
    left: 45%;
}

/* HRV report form */
.hrv-report-form .date-label
{
    font-weight: 700;
    font-size: 16px;
}
.hrv-report-form .si-datetime-container
{
    margin-bottom: 30px;
}
.hrv-report-form .si-datetime-container .si-datetime-item label
{
    margin-top: 20px;
}
.hrv-report-form .datetime-input.error
{
    border-color: red;
};

/* INFO TAGS */
.info-tag .popover
{
    max-width: 500px;
    overflow-x: auto;
}
.info-tag .glyphicon
{
    text-shadow: -1px 1px 2px #ccc;
    color:rgba(68, 92, 110, 1);
    cursor: pointer;
    z-index: 1000;
    transition: all 0.35s ease-in-out;
    transform:scale(1.3);
}
.info-tag .glyphicon:hover
{
    text-shadow: 2px 3px 15px #ff9c35;
    transform:translateY(-3px) scale(1.7);
}
.si-info-tag
{
    text-shadow: -1px 1px 2px #ccc;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
}
.si-info-tag:hover
{
    text-shadow: 2px 3px 15px #ff9c35;
    transform:translate(-3px, -1px) scale(1.3);
}

.interactive-graph-info .si-info-tag
{
    left: 8px;
}
.interactive-graph-info .gray-background
{
    background-color: rgba(220, 220, 220, 0.2);
    border-radius: 8px;
}

/* info tags expanded content */
.interactive-graph-info .info-expanded .slideHandler,
.interactive-graph-info .info-expanded .graph-key 
{
    display:inline-block;
    margin-right: 3px;
}
.interactive-graph-info .info-expanded span.slideHandler.green
{
    background-color: #33ff33;
}
.interactive-graph-info .info-expanded span.slideHandler.red
{
    background-color: #ff3333;
}
.interactive-graph-info .info-expanded button.btn-primary
{
    vertical-align: bottom;
    font-size: 12px;
    padding: 2px 4px;
}
.interactive-graph-info .info-expanded span.graph-key
{
    width: 30px;
    height: 6px;
}
.interactive-graph-info .info-expanded span.graph-key.hfc
{
    background-color: rgb(29, 77, 124);
}
.interactive-graph-info .info-expanded span.graph-key.lfc
{
    background-color: rgb(48, 118, 180);
}
.interactive-graph-info .info-expanded span.graph-key.vlfc
{
    background-color: rgb(241, 151, 55);
}

/*
 * ANIMATION KEYFRAMES
 */
@-webkit-keyframes scale-down
{
    0% {transform: scaleY(1);}
    100% {transform: scaleY(0);}
}
@keyframes scale-down
{
    0% {transform: scaleY(1);}
    100% {transform: scaleY(0);}
}
@-webkit-keyframes scale-up
{
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
}
@keyframes scale-up
{
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
}

.boxComponent, .box-component
{
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #b3bfc7;
    border-radius: 5px;
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
    max-width: 950px;
}
h2.component-action
{
    font-size: 22px;
    margin-top: 8px;
    text-align: right;
}
.box-component
{
    padding: 20px 10px;
    width: calc(100vw - 40px);
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 0px 6px 3px #C2C2C2;
    box-shadow: 0px 0px 6px 3px #C2C2C2;
    .box-component-inner
    {
        width: 794px;
        margin: auto;
    }
}
.boxComponent .title, h2.componentTitle,
.box-component .title, h2.component-title
{
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 4px;
    text-align: left;
    color: #445c6e;
    margin-top: 5px;
}
.boxComponent .title, h2.componentTitle span,
.box-component .title, h2.component-title span
{
    letter-spacing: 1px;
    font-weight: 400;
}
.boxComponent .content,
.box-component .content
{
    padding: 0 8px;
}
.boxComponent.sqiGraph, .boxComponent.notification,
.box-component.sqiGraph, .box-component.notification
{
    min-height: 200px;
}

/* report paper-like page style */
.box-component.report
{
    box-shadow: 0px 0px 5px 2px #cccccc;
    border: 1px solid #c1c1c1;
}

.spacer-10
{
    height: 10px;
}
.spacer-20
{
    height: 20px;
}
.spacer-30
{
    height: 30px;
}
.loader-text
{
    color: #ff9a35;
    font-size: 22px;
    font-weight: bold;
}
.margin-top-10
{
    margin-top: 10px;
}
.animation-setup
{
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
}
.stepchart
{
/*
  min-width: 400px;
  width: calc(100vw - 300px);
  max-width: 780px;
  margin-left: -10px;
  height: 260px;
  vertical-align: middle;
  display: inline-block;
*/
}

.slideHandler
{
    display: inline-block;
    position: relative;
    height: 22px;
    width: 5px;
    background: #33ff33;
    border: 1px solid #555;
    z-index: 15;
    top: 6px;
}
.slideHandler.red
{
    background-color: #cc0000;
}
/* INFO TAGS */
.info-tag .popover
{
    max-width: 500px;
    overflow-x: auto;
}
.info-tag .glyphicon
{
    text-shadow: -1px 1px 2px #ccc;
    color:rgba(68, 92, 110, 1);
    cursor: pointer;
    z-index: 1000;
    transition: all 0.35s ease-in-out;
    transform:scale(1.3);
}
.info-tag .glyphicon:hover
{
    text-shadow: 2px 3px 15px #ff9c35;
    transform:translateY(-3px) scale(1.7);
}
.si-info-tag
{
    text-shadow: -1px 1px 2px #ccc;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
}
.si-info-tag:hover
{
    text-shadow: 2px 3px 15px #ff9c35;
    transform:translate(-3px, -1px) scale(1.3);
}
.interactive-graph-info .si-info-tag
{
    left: 8px;
}
.interactive-graph-info .gray-background
{
    background-color: rgba(220, 220, 220, 0.2);
    border-radius: 8px;
}

/* info tags expanded content */
.interactive-graph-info .info-expanded .slideHandler,
.interactive-graph-info .info-expanded .graph-key
{
    display:inline-block;
    margin-right: 3px;
}
.interactive-graph-info .info-expanded span.slideHandler.green
{
    background-color: #33ff33;
}
.interactive-graph-info .info-expanded span.slideHandler.red
{
    background-color: #ff3333;
}
.interactive-graph-info .info-expanded button.btn-primary
{
    vertical-align: bottom;
    font-size: 12px;
    padding: 2px 4px;
}
.interactive-graph-info .info-expanded span.graph-key
{
    width: 30px;
    height: 6px;
}
.interactive-graph-info .info-expanded span.graph-key.hfc
{
    background-color: rgb(29, 77, 124);
}
.interactive-graph-info .info-expanded span.graph-key.lfc
{
    background-color: rgb(48, 118, 180);
}
.interactive-graph-info .info-expanded span.graph-key.vlfc
{
    background-color: rgb(241, 151, 55);
}
.btn
{
    display: inline-block;
    background-color: $orange-6;//#ff9a35;
    border-color: $orange-6;//#e68b30;
    color: #ffffff;
    // text-transform: uppercase;
    text-transform: none;
    font-family: "Sansation", "Helveticas Neue", "sans-serif";
    font-size: 14px;
    cursor: pointer;
    margin: 0px 1px 0px 1px;
    border-radius: 4px;
    border: none;
}
.btn:hover,
.btn:active,
.btn:visited,
.btn:checked,
.btn:focus 
{
    background-color: $orange-5;
    border: none;
}
// .btn {
//   background-color: $orange-6;//#ff9c35;
//   border-color: rgb(230, 139, 48);
// }
// .btn:hover {
//   background-color: $orange-5;//#ec8a20;
//   border-color: rgb(190, 109, 27);
// }

.test-summary-result-value
{
    font-weight: bold;
}
.annotation
{
    cursor: pointer !important;
    font-size: 12px !important;
    width: 35px !important;
    position: absolute !important;
    display: inline-block !important;
    z-index: 45 !important;
    height: 20px!important;
}
.dygraph-legend
{
    width: 300px;
    right: 0px!important;
    left: unset!important;
}
.dygraph-annotation
{
    position : absolute;
    display:inline-block;
    z-index: 45;
}
.dygraphDefaultAnnotation 
{
    border: 1px solid black;
    background-color: white;
    text-align: center;
}

.share-button 
{
    background: none;
    border: none;
}

.share-button .mat-tooltip 
{
    white-space: nowrap !important;
}

.btn-default:not(.ui-select-toggle) 
{
    background-color: #445c6e;
    border-color: #445c6e;
    color: #ffffff;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, 
.btn-default.active, .open > .dropdown-toggle.btn-default 
{
    background-color: #ff9a35;
    border-color: #26343e;
    color: #ffffff;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, 
.btn-default.disabled:hover, .btn-default[disabled]:hover, 
fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, 
.btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, 
.btn-default.disabled:active, .btn-default[disabled]:active, 
fieldset[disabled] .btn-default:active, .btn-default.disabled.active, 
.btn-default.active[disabled], fieldset[disabled] .btn-default.active
{
    background-color: #ccc;
}

.green-pointer-text
{
    color: #00ff00;
    margin-top: 20px;
    display: block;
}
